.circular-template {
  width: 450px;
  min-width: 450px;
  height: 930px;
  min-height: 930px;
  position: relative;

  // background-color: red;
  .company {
    max-width: 112px;
    max-height: 36px;
    min-width: 48px;
    object-fit: contain;
    margin: 14px 0 0 8px;
    z-index: 1;
    position: absolute;
  }

  .infosec {
    text-align: right;
    padding: 1px 8px;
    position: absolute;
    right: 0;
    top: 110px;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 450px;
    height: 930px;
    object-fit: fill;
    // display: none
  }

  .fg {
    position: absolute;
    z-index: 1;
  }

  .all {
    word-wrap: break-word
  }

  .title-container {
    margin: 53px 0 0 5px;
    transform: rotate(348deg);
    width: 300px;
    height: 88px;
    line-height: 1.3;
    display: table;
    p {
      margin: 0;
    }
    .title{
      display: table-cell;
      vertical-align: middle;
    }
    // background-color: #ff1;
  }

  .info {
    margin-top: 44px;
    padding: 2px 12px;
    width: 432px;
    height: 82px;

    p {
      margin: 0;
    }

    // background-color: #f2f;
  }

  .desc-body {
    padding: 4px 12px;
    width: 450px;
    height: 548px;

    // background-color: #2ff;
    .list {
      text-align: justify;
      ul {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;
        // text-indent: -1em;

        li {
          position: relative;
          padding-left: 20px;
          margin-bottom: 10px;
          line-height: 1.3;
          &:before {
            position: absolute;
            left: -6px;
            top: 3px;
            width: 12px; 
            height: 12px; 
            content: "";
            background: url(../../assets/images/circular-list-icon.png) no-repeat;
            background-size: cover;
          }
        }
      }
    }

    img[src=""] {
      display: none;
    }
  }

  .eval {
    max-width: 224px;
    max-height: 360px;
    float: right;
    top: -40px;
    position: relative;
    object-fit: fill;
    margin-left: 8px;
  }

  .tag {
    max-width: 132px;
    max-height: 83px;
    position: absolute;
    object-fit: fill;
    transform: rotate(348deg);
    right: 50px;
    top: 12px;
  }

  .closing {
    height: 40px;
    margin-top: 0px;
    width: 450px;
    display: table;
    // background-color: #Af3;
    .cell{
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }

  .footer {
    height: 68px;
    margin-top: 4px;
    width: 450px;
    position: relative;
    padding: 4px 4px 0 4px;

    .content {
      height: 52px;
      * {
        line-height: 1;
      }
    }
    .var-row{
      position: absolute;
      bottom: 0px;
      padding: 0 2px;
      width: 440px;
    }
    .var {
      margin-left: 12px;
      // background-color: red;
    }

    // background-color: #fE3;
  }

  .ck-container {
    margin-bottom: 0 !important;
    * {
      font-size: inherit;
      font-family: inherit;
    }
  }
}

.circular-preview-dialog {
  width: 450px;
  padding: 0;

  // max-height: 900px !important;
  .dialog-actions {
    padding: 0;
  }
}