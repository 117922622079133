.evaluation-question-card {
  .header {
    padding: 16px 0;
    margin-bottom: 16px;
    box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
    .font-input {
      margin: 0 0 0 24px;

      .label-cont {
        .label {
          color: #1890FF;
        }
      }
    }
    .container {
      width: 55px;
      padding: 4px;
      .input {
        text-align: center;
      }
    }
  }
  .question-form{
    .label-cont{
      margin-bottom: 8px;
    }
  }
}