.heading {
    padding-top: 12px;
}
.pl {
    padding-left: 25px;
}
.pt {
    padding-top: 25px;
}
.delete-guide{
    width: 400px;
}