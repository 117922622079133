.field-editor {
  padding-left: 20px;
}

.con-editor-container {
  // padding-left: 16rem;
  // padding-right: 2rem;
  width: 100%;
  height: 80px;
}

.con-ck-container {
  margin-bottom: 2rem;
  // padding-left: 7rem;
}

.default {
  font-size: 14px;
  text-align: justify;
  padding-left: 1rem;
  padding-right: 18rem;

}

.shadow-box {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  height: 3px;
  margin: 5px -20px 12px -20px;
}

.activate-popup {
  width: 33vw;

  .MuiDialogActions-root {
    justify-content: flex-start;
    flex-direction: row-reverse;
    box-shadow: none;

    .btn:nth-child(2) {
      margin-left: 0;
    }
  }

  .title-sec {
    padding: 0 1.6rem 1.6rem 0;
  }
}

.screen-pad-con {
  padding: 2.0rem 3.0rem;
}

.field-row-con {
  width: 650px;
  margin: 0.8rem 0;
  flex-wrap: wrap;

  .font-input {
    width: 48%;

    .label-cont {
      min-width: 60px;
      margin-top: 0;

      .label {
        width: 100px;
      }

      .colon {
        margin: 0 8px;
      }
    }

    &:nth-child(even) {
      margin-left: 24px;

      .label-cont {
        min-width: 35px;

        .label {
          width: 35px;
        }
      }

      &.readOnly {
        margin-left: 0;
      }
    }

    &.readOnly {
      .container {
        padding-left: 2px;
      }

      .input {
        width: 80%;
        font-weight: bold;
      }
    }
  }

  .note-input {
    width: 100%;

    .colon {
      margin-right: 0.5rem;
    }
  }
}

.astrick {
  position: relative;
}

.astrick::after {
  content: '*';
  color: rgb(244, 67, 54);
  // position: absolute;
  font-size: 18px;
  left: 0;
  // margin-left: -10px;
  margin-right: 8px;
}

.highlighted {
  border: 0.1rem solid rgb(251, 165, 165);
  /* Add a red border for highlighting */
  box-shadow: 0 0 10px rgba(251, 165, 165, 0.4);
  /* Add a shadow effect */
}

.label-con {
  width: 100px;
}

.dialog-width {
  width: 70vw;
}

.history-dialog {
  width: 50vw;

  .ant-table-cell {
    padding-left: 16px !important;
  }

  .ant-table-expanded-row {
    .ant-table-cell {
      padding: 0px !important;
    }
    .header, .summary{
      padding: 8px 8px 8px 16px;
    }
    .summary{
      
    }
  }
}
.field-row-guide {
  width: 650px;
  margin: 0.8rem 0;
  display: flex;

  .font-input {
    .label-cont {
      margin-top: 0;
    }

    &:nth-child(even) {
      margin-left: 24px;

      &.readOnly {
        margin-left: 0;
      }
    }

    &.readOnly {
      .container {
        padding-left: 2px;
      }
      .input {
        font-weight: bold;
      }
    }
  }
}