.learning-template {
  position: relative;
  .title-sec{
    padding-bottom: 16px;
  }
  .dialog-actions {
    display: none;
  }
  .btn-edit{
    position: absolute;
    right: 24px;
    top: 16px;
    padding: 0;
    text-decoration: none;
  }
}