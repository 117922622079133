.font-input {
  margin-bottom: 16px;
  .add-var-btn{
    margin-top: 8px;
  }
  .help-text{
    margin-left: 4px;
  }
  .error {
    margin: 0 4px 0 4px;
  }

  &.radio-cont {
    .container {
      border: none;
      padding-left: 0;
    }

    .hint {
      margin-top: 4px;
    }

    .label-cont {
      margin-top: 8px;
    }
  }

  &.readOnly .label::after {
    display: none;
  }

  .label {
    padding-right: 4px;
  }

  .label-cont {
    // min-width: 72px;
    margin-top: 8px;
  }

  .colon {
    margin-right: 4px;
  }

  .container {
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    padding: 4px 12px;

    &.readOnly {
      border: none;
    }
    &.error {
      border-color: #FE3333;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #EFEFEF;
    }

    &.html {
      border: none;
      padding: 0;
    }

    &.disabled.readOnly {
      background-color: transparent;
    }
  }

  .input {
    border: none;
    outline: none;
    line-height: 2.2rem;
    width: 100%;
    resize: none;
    background-color: transparent;
    margin-top: 0;
  }
  .input::placeholder {
    color: #dcdcdc; /* Placeholder color */
  }
  .input:not(:placeholder-shown)::placeholder {
    color: black; /* Color when input has text */
  }

  &.input-col,
  &.column {
    .label {
      margin-bottom: 0.4rem;
    }
  }

  &.req {
    .label, .astrick {
      position: relative;
    }

    .astrick::after {
      content: '*';
      color: rgb(244, 67, 54);
      position: absolute;
      font: inherit;
      left: 0;
      margin-left: 2px;
    }
  }

  .btn .label{
    padding-right: 0;
    &::after {
      display: none;
    }
  }

  .count {
    text-align: right;
  }
}

.dropdown-list {
  .option[aria-selected="true"] {
    background-color: transparent !important;
    color: #0033CC;
  }
}

.dropdown {
  border: 1px solid #D9D9D9;

  .icon {
    margin-right: 0.8rem;
  }

  &:focus,
  &:hover {
    outline: none !important;
    border: 1px solid #D9D9D9;
  }
}

.dropdown-item {

  .icon,
  .label {
    color: inherit;
  }

  .icon {
    margin-right: 0.8rem;
  }

  &.Mui-selected {
    background-color: transparent !important;
  }

  &.primary:hover {
    background-color: #fff !important;

    .icon,
    .label {
      color: #0033CC;
    }
  }
}
.w-70pix{
  width: 70px !important;
}
.w-90pix {
  width: 90px !important;
}
.w-115pix{
  width:115px !important;
}
.w-130pix {
  width: 130px !important;
}
.w-170pix {
  width: 170px !important;
}
.mright{
 margin-right: 2px !important;
}
.w-500pix {
  width: 500px !important;
}
.w-110px {
  min-width: 110px;
}
.w-300pix {
  width: 300px !important;
}
.container-guide {
  margin-top: 7px;
}